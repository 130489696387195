@import "../mainVariables";
@import "../mixins";

.sponsors-wrapper {
  width: 1120px;
  padding: 0 0 100px 0;
  background-color: white;

  .sponsors-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    color: #667085;
    margin: 48px 0;
    text-align: center;
  }


  .sponsors-list-wrapper {
    position: relative;

    .slick-slider {
      .slick-list {
        .slick-track {
          margin-left: auto;
          padding-top: 5px;
          @media screen and (max-width: 400px){
            margin-right: 90px;
          }
        }
      }
    }
    .slick-slide .slick-active{
      width: 300px;
    }

    .sponsors-item {
      //width: 90%;
      //height: 100%;
      //position: relative;
      width: 100%;
      height: 100%;
      margin-right: 20px;

      .sponsors-content {
        //width: 100%;
        //height: 100%;
        padding-top: 35.2%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        @media screen and (max-width:400px){
          padding-top:40% ;
        }
      }


      //.fade-image {
      //  z-index: 1;
      //  opacity: 1;
      //}
      //
      //.main-image {
      //  z-index: 0;
      //  opacity: 0;
      //}

    }
  }

  @media screen and (max-width: 1250px){
    width: 1020px;
  }

  @media all and (max-width: 1050px) {
    padding: 0 0 100px $mainPadding;
    width: 100%;
    .sponsors-title {
      font-size: 28px;
      margin: 80px 0;
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }
  @media all and (max-width: 700px) {
    .sponsors-title {
      font-size: 22px;
      margin: 60px 0;
    }
  }
  @media all and (max-width: 400px) {
    .sponsors-title {
      font-size: 22px;
      margin: 60px 0;
    }
  }
}