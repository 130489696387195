@import "../mainVariables";
@import "../mixins";

.security-blocks{
  width: 1120px;
  background-color: #FFFFFF;
  padding: 60px 0 80px;
  .security-title{
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.02em;
    text-align: left;
    color:$textColor;
  }
  .block-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .block{
      width:calc(90% / 3);
      margin: 40px 30px 0 0;
      .title{
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        color:$textColor;
        margin-top: 24px;
      }
      .description{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin-top: 12px;
      }
    }
  }

  @media screen and (max-width: 1250px){
    width: 1020px;
  }
  @media screen and (max-width: 1100px){
    width: 100%;
    padding: 0 $mainPadding 40px;
    .security-title{
      font-size: 26px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: -0.02em;
      text-align: left;
    }
    .block-wrapper{
      flex-wrap: unset;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 32px;
      .block{
        width: 100%;
        margin-right: unset;
        .title{
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 12px;
        }
        .description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
        }
      }
    }
  }
}