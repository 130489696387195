@import "../mainVariables";
@import "../mixins";

.consultation-wrapper {
  padding: 80px 0;
  position: relative;
  width: 100%;

  .leftBg {
    width: 260px;
    height: 55%;
    background-color: $brandColor;
    position: absolute;
    left: -92px;
    top: 118px;
    rotate: 160deg;

    @media screen and (max-width: 1300px) {
      left: -132px;
    }
  }

  .rightBg {
    width: 244px;
    height: 55%;
    background-color: #D0D5DD;
    position: absolute;
    right: -80px;
    bottom: 114px;
    rotate: 160deg;

    @media screen and (max-width: 1300px) {
      right: -116px;
    }
  }

  .consultation-block {
    position: relative;
    margin: 0 $mainPadding;
    padding: 68px 110px;
    background-color: $textColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 226px;

    .consultation-info {
      width: 46%;

      .consultation-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
      }

      .consultation-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-top: 16px;
      }
    }

    .consultation-number {
      width: 46%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .number {
        background-color: white;
        border-radius: 32px;
        padding: 12px 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 12px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: $textColor;
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    padding: 70px 0 0;
    .leftBg{
      display: none;
    }
    .rightBg{
      display: none;
    }
    .consultation-block{
      margin: unset;
      padding: 72px 22px;
      height: unset;
      flex-direction: column;
      .consultation-info{
        width: 100%;
        .consultation-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .consultation-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 16px;
        }
      }
      .consultation-number{
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        .number{
          width: 100%;
        }
      }
    }
  }
}