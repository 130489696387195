@import "../mainVariables";
@import "../mixins";

.work-page {
  //-webkit-user-select: none !important;
  //user-select: none !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .work-page-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .work-main-block {
      padding: 80px 0;
      width: 918px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-block-subtitle {
        width: 216px;
        height: 48px;
        background-color: #FFEAEA;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        color: $brandColor;
        border-radius: 24px;
      }

      .main-block-title {
        font-size: 52px;
        font-weight: 700;
        line-height: 72px;
        letter-spacing: 0;
        text-align: center;
        color: $textColor;
        //margin-top: 16px;
      }

      .main-block-description {
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
        text-align: center;
        color: #475467;
        margin-top: 24px;
      }
    }
    .works-wrapper{
      padding: 80px 0;
      width: 1120px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .work-block{
        background-color:#F9FAFB;
        padding: 32px;
        margin-top: 24px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        .work-img{
          width: 320px;
          height: 288px;
          margin-bottom: 32px;
          .img-wrapper{
            position: relative;
            padding-top: 90%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 16px;
              object-fit: cover;
            }
          }
        }
        .work-info{
          .info-title{
            font-size: 36px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0;
            text-align: left;
            color:$textColor;
          }
          .info-description{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #475467;
            margin-top: 12px;
          }
          .info-option{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .icon{
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #FFD5D5;
              margin-right: 12px;
            }
            span{
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color: #475467;
            }
          }
        }
        &:nth-child(2n){
          width: 58%;
        }
        &:nth-child(3n+3){
          width: 100%!important;
          display: flex!important;
          align-items: center!important;
          flex-direction: unset!important;
          justify-content: space-between!important;
          .work-img{
            width: 36%!important;
            height: unset!important;
            margin-bottom: unset!important;
            margin-right: 32px;
            .img-wrapper{
              position: relative;
              padding-top: 118.7%;
              img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                object-fit: cover;
              }
            }
          }
          .work-info{
            width: 64%;
          }
        }
        &:nth-child(2n+1){
          width: 40%;
        }
      }
    }
  }
  @media screen and (max-width: 1200px){
    .works-wrapper{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px){
    .work-page-wrapper{
      .work-main-block{
        padding: 52px $mainPadding;
        width: 100%;
        .main-block-subtitle{
          width: 208px;
          height: 40px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
        }
        .main-block-title{
          font-size: 36px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 24px;
        }
        .main-block-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 24px;
        }
      }
      .works-wrapper{
        width: unset;
        padding: 20px 0 80px;
        flex-wrap: unset;
        flex-direction: column;
        .work-block{
          width: 100%!important;
          padding: 22px;
          &:nth-child(3n+3){
            flex-direction: column!important;
            .work-img{
              width: 100%!important;
              margin-right: unset;
              .img-wrapper{
                position: relative;
                padding-top: 88%;
                img{
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 16px;
                }
              }
            }
            .work-info{
              width: 100%;
            }
          }
          .work-img{
            width: 100%!important;
            height: 100%;
            margin-bottom: unset!important;
            margin-right: 32px;
            .img-wrapper{
              position: relative;
              padding-top: 88%;
              img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 16px;
              }
            }
          }
          .work-info{
            width: 100%;
            margin-top:32px;
            .info-title{
              font-size: 24px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: -0.02em;
              text-align: left;
            }
            .info-description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
