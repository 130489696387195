@import "../mainVariables";
@import "../mixins";

.services {
  width: 100%;
  padding: 80px $mainPadding 60px;
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 1120px;
    .subtitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #667085;
    }

    .header-text {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 32px;

      .title {
        width: 49%;
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: left;
        color: $textColor;
      }

      .link-wrapper {
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .description {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0;
          text-align: left;
          color: #475467;
        }

        .project-more-news {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          margin-top: 20px;
          color: $brandColor;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg {
            transition: transform 0.2s;
            transform: rotate(0deg);
          }

          &:hover {
            svg {
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }
    }
  }

  .services-wrapper {
    width: 1120px;
    display: flex;
    align-items: center;
    margin-top: 60px;
    box-shadow: 0px 2px 14px 0px #D9D9D982;

    .service-block {
      width: calc(100% / 3);
      padding: 80px 32px;
      border-right: 1px solid #D0D5DD;

      &:last-child {
        border-right: unset;
      }

      .service-icon-wrapper {
        img{
          width: 52px;
          height: 52px;
        }
      }

      .hover-icon-wrapper {
        display: none;
      }

      .service-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        color: $textColor;
        margin-top: 26px;
      }

      .service-description {
        height: 96px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: $textColor;
        margin-top: 16px;
      }
    }

    :hover {
      background-color: $brandColor;

      .service-icon-wrapper {
        display: none;
      }

      .hover-icon-wrapper {
        img{
          width: 52px;
          height: 52px;
        }
        display: flex;
      }

      .service-title {
        color: white;
      }

      .service-description {
        color: white;
      }
    }
  }

  .services-more-info {
    width: 1120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 34px;
    box-shadow: 0px 2px 14px 0px rgba(217, 217, 217, 0.51);

    .info {
      width: 70%;
      display: flex;
      align-items: center;

      p {
        width: 64px;
        height: 64px;
        background-color: #F2F4F7;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        width: 90%;
        margin-left: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #98A2B3;
      }
    }

    .all-info-btn {
      cursor: pointer;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      color: $textColor;
      border: 1.5px solid #FE2F2F;
      border-radius: 24px;
      width: 30%;
    }
  }

  @media screen and (max-width: 1250px){
    .header{
      width: 1020px;
    }
    .services-wrapper{
      width: 1020px;
    }
    .services-more-info{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px) {
    //z-index: -1;
    padding: 60px $mainPadding 0;
    .header {
      width: 100%;
      .header-text {
        flex-direction: column;
        margin-top: 20px;

        .title {
          width: 100%;
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.02em;
          text-align: left;
        }

        .link-wrapper {
          width: 100%;
          margin-top: 16px;

          .description {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left;
          }
        }
      }
    }
    .services-wrapper {
      width: 100%;
      background-color: #F2F4F7;
      flex-direction: column;

      .service-block {
        width: 100%;
        padding: 40px 30px;
        border-bottom: 1px solid #D0D5DD;
        border-right: unset;
        pointer-events: none;

        &:last-child {
          border-bottom: unset;
        }
      }
    }
    .services-more-info {
      width: 100%;
      flex-direction: column;
      padding: 32px 14px 82px 14px;
      box-shadow: unset;

      .info {
        width: 100%;
        p{
          width: 48px;
          height: 48px;
        }
      }

      .all-info-btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
