@import "../mainVariables";
@import "../mixins";

.ant-modal-content{
  .ant-modal-body{
    padding: 24px 0;
    .body-wrapper{
      .inputs-wrapper{
        padding: 0 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-inputs-block{
          width: 49%;
        }
        .right-inputs-block{
          width: 49%;
        }
        .custom-input-group{
          label{
            color: #101828;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
          }
          input{
            border: 1px solid #98A2B3;
            color: #101828;
            &::placeholder{
              color: #98A2B3;
              font-size: 13px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
            }
          }
        }
      }
      .radio-btn-block{
        padding: 0 24px;
        margin-top: 10px;
        margin-bottom: 24px;
        .radio-btn-title{
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #101828;
        }
        .radio-btn-secTitle{
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #101828;
          margin-top: 20px;
        }
        .top-radio-block,.bottom-radio-block{
          margin-top: 8px;
          .ant-radio-group{
            display: flex;
            flex-direction: column;
            .ant-radio-wrapper{
              margin-bottom: 4px;
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0;
                text-align: left;
                color: #475467;
              }
            }
          }
        }
      }
      .bodes-footer{
        width: 100%;
        border-top: 1px solid #EAECF0;
        padding-top: 24px;
        .checkin-block{
          padding: 0 24px;
          .ant-checkbox-wrapper{
            span{
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0;
              text-align: left;
              color: #475467;
            }
            .ant-checkbox{
              margin-right: 10px;
            }
          }
        }
        .download-block{
          margin: 12px 56px 34px ;
          width: 258px;
          border-bottom: 1px solid #101828;
          display: flex;
          align-items: center;
          padding-bottom: 4px;
          span{
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #101828;
            margin-left: 4px;
          }
        }
      }
    }
    @media screen and (max-width: 1100px){
      .body-wrapper{
        .inputs-wrapper{
          flex-direction: column;
          .left-inputs-block{
            width: 100%;
          }
          .right-inputs-block{
            width: 100%;
          }
        }
      }
    }
  }
}