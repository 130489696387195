@import "../mainVariables";
@import "../mixins";

.contacts-wrapper {
  width: 100%;
  position: relative;
  .contactsRedBg{
    width: 390px;
    height: 265px;
    background-color:$brandColor;
    position: absolute;
    left: -178px;
    rotate: 150deg;
    top: 160px;
    @media screen and (max-width: 1400px){
      top: 166px;
      height: 194px;
      left: -234px;
    }
  }
  .contactsGrayBg{
    width: 400px;
    height: 200px;
    background-color: #D0D5DD;
    position: absolute;
    right: -58px;
    rotate: -20deg;
    top: 94px;
    @media screen and (max-width: 1400px){
      top: 102px;
    }
  }
  .form-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:$textColor;
    margin:80px $mainPadding;
    padding: 74px 54px;
    height: 308px;
    position: relative;
    z-index: 2000;
    .contacts-info-block{
      width: 48%;
      .title{
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
      }
      .description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-top: 16px;
      }
    }
    .subscribe-contact-info{
      width: 44%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .input-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        .custom-input-group{
          width: 100%;
          margin-bottom: unset;
          margin-top: 32px;
          input{
            background-color: white;
            color:$textColor;
            margin-top: unset;
            height: 48px;
            &::placeholder{
              color: #A5A5A5;
            }
          }
        }
        .sent-email{
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          color:$textColor;
          border-radius: 32px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 32px;
        }
      }
      .subscribe-phones-wrapper{
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 30px;
        .number {
          background-color: white;
          border-radius: 32px;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;

          span {
            margin-left: 12px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: $textColor;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    .contactsGrayBg{
      display: none;
    }
    .contactsRedBg{
      display: none;
    }
    .form-content{
      margin: unset;
      height: unset;
      padding: 80px 16px;
      flex-direction: column;
      .contacts-info-block{
        width: 100%;
        .title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: left;
        }
      }
      .input-wrapper{
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
        .custom-input-group{
          width: 100%;
        }
        .sent-email{
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }
}
