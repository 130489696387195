@import "../fonts";
@import "../mainVariables";


.systems-wrapper{
  width: 100%;
  background-color: #F2F4F7;
  padding: 148px 0;
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .systemsBlocks-title{
    width: 1120px;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.02em;
    text-align: center;
    color:$textColor;
  }
  .systems-blocks{
    width: 1120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px;
    .systems{
      width: calc(94% / 3);
      height: 248px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .system-icon{
        img{
          width: 100%;
          height: 100%;
        }
      }
      .system-title{
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: center;
        color:$textColor;
        margin-top: 36px;
      }
      .systems-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #475467;
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .systemsBlocks-title{
      width: 1020px;
    }
    .systems-blocks{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1100px){
    margin-top: 90px;
    padding: 52px $mainPadding 90px;
    .systemsBlocks-title{
      width: 100%;
      font-size: 26px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.02em;
      text-align: center;
    }
    .systems-blocks{
      width: 100%;
      flex-direction: column;
      .systems{
        width: 100%;
        height: unset;
        margin-top: 40px;
        .system-title{
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.02em;
          text-align: center;
          margin-top: 24px;
        }
        .systems-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }
    }
  }
}