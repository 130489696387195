@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 100%;
  padding: 60px $mainPadding 100px $mainPadding;
  border-top: 1px solid #DCE0E8;
  background-color: #101828;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .partners-info {
    width: 1120px;

    .partners-subtitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #F2F4F7;
    }

    .partners-title {
      font-size: 36px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
      margin-top: 32px;
    }
  }

  .partners-list-wrapper {
    width: 1120px;
    position: relative;
    margin-top: 64px;

    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .partners {
              padding-right: 40px;

              .partner-item {
                padding: 46px 0 46px 40px;
                background-color: white;

                .partners-name {
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 36px;
                  letter-spacing: 0;
                  text-align: left;
                  color: $textColor;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  height: 72px;

                  @media screen and (max-width: 1700px){
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
                .img-wrapper{
                  width: 100%;
                  height: 100%;
                  margin-top: 32px;
                  .partner-content {
                    width:200px;
                    height:48px;
                    //position: relative;
                    //padding-top: 18%;
                    img{
                      //position: absolute;
                      //top: 0;
                      //left: 0;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .partner-hover-content{
                    display: none;
                    width:200px;
                    height:48px;
                    img{
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }
                &:hover {
                  background-color: $brandColor;
                  .img-wrapper{
                    .partner-content{
                      display: none;
                    }
                    .partner-hover-content{
                      display: flex;
                    }
                  }

                  .partners-name {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1020px){
    .partners-info{
      width: 1020px;
    }
    .partners-list-wrapper{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1100px) {
    .partners-info {
      width: 100%;
      .partners-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
    .partners-list-wrapper{
      width: 100%;
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              .partners {
                padding-right: 20px;

                .partner-item {
                  padding: 20px 20px 20px 20px;

                  .partners-name {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0;
                    text-align: left;
                    -webkit-line-clamp: 3;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}