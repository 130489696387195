@import "../fonts";
@import "../mainVariables";

.contactsBlock-wrapper {
  width: 100%;
  position: relative;
  background-color: white;

  .contactsBlock-block {
    background-color: #F2F4F7;
    padding: 100px $mainPadding 40px;
    display: flex;
    justify-content: space-between;

    .contact-main-wrapper {
      width: 48%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .contact-main-subtitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        text-transform: uppercase;
      }

      .contact-main-title {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
        color: $textColor;
        margin-top: 12px;
      }

      .contact-main-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 24px;
        color: #475467;
      }
    }

    .inputs-wrapper {
      width: 48%;
      display: flex;
      flex-direction: column;

      .fullName-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .custom-input-group {
          width: 48%;
        }
      }

      .custom-input-group {
        label {
          color: #667085;
        }

        input {
          color: #667085;
          border-color: #667085;

          &::placeholder {
            color: #667085;
          }
        }
      }

      .sent-email {
        border-radius: 24px;
        height: 48px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
        background-color: $brandColor;
        color: white;
      }
    }
  }

  @media screen and (max-width: 1100px) {

    .contactsBlock-block {
      margin: unset;
      padding: 60px 28px 40px;
      flex-direction: column;

      .contact-main-wrapper {
        width: 100%;

        .contact-main-subtitle{
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
        }

        .contact-main-title {
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0;
          text-align: left;
        }

        .contact-main-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 16px;
        }
      }

      .inputs-wrapper {
        width: 100%;
        margin-top: 44px;

        .fullName-wrapper {
          flex-direction: column;

          .custom-input-group {
            width: 100%;
          }
        }

        .sent-email {
          margin-top: 32px;
        }
      }
    }
  }
}