@import "../../mainVariables";
@import "../../mixins";
@import "../../fonts";

.link-button {
  //width: 200px;
  height: 60px;
  border-radius: 50px;
  background-color: $brandColor;
  @include flex-center;
  font-family: Medium;
  font-size: 18px;
  color: #fff;
  border: none;
  transition: all 0.5s ease 0s;
  letter-spacing: 1px;
  white-space: nowrap;
  padding: 0 50px;

  &:disabled {
    color: #fff;
    background: #dadada !important;
    cursor: default;
  }

  &.hover-bg-change {
    &:hover {
      &:not(:disabled) {
        background-color: $pinkColor !important;
        color: white;
      }
    }
  }

  &.xl {
    height: 45px;
    width: 160px;
    @include font(Bold, 13);
    @media all and (max-width: 420px) {
      @include font(Medium, 12);
      height: 35px;
      width: 120px;
    }
  }

  &.lg {
    height: 40px;
    width: 140px;
    @include font(Bold, 13);
    @media all and (max-width: 576px) {
      @include font(Medium, 12);
      height: 32px;
      width: 120px;
    }
  }

  &.md {
    height: 30px;
    width: 100px;
    @include font(Bold, 12);
    @media all and (max-width: 768px) and (min-width: 576px) {
      @include font(Medium, 11);
      height: 28px;
      width: 90px;
    }
    @media all and (max-width: 480px) {
      @include font(Medium, 9);
      height: 25px;
      width: 70px;
    }
  }

  &.bg-white {
    background-color: white;
    color: $brandColor;
    border: 1px solid $brandColor;

  }

  &.bg-grey {
    background-color: #DFDFDF;
    color: $brandColor;

  }

  &.bg-red {
    background-color: #FF2540;
    color: white;
    border: none;
  }

  &.bg-orange {
    background-color: #FFB03F;
    color: white;
    border: none;
  }

  .flex-row {
    display: flex;
    align-items: center;

    .ant-spin {
      margin-left: 8px;
    }
  }

  &:hover{
    color: $brandColor;
    background-color: white;
  }
}

@keyframes heart-anim {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.arrows {
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: 50px;
  right: $mainPadding;
  display: flex;

  .next-btn, .prev-btn {
    background-color: white;
    position: unset;
    top: unset;
    transform: unset;
    transition: all 0.2s;
    &:hover{
      background-color: $brandColor;
      svg{
        path{
          stroke: white!important;
        }
      }
    }
  }

  .next-btn {
    margin-left: 10px;
  }
}

.next-btn, .prev-btn {
  z-index: 1;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  @include flex-center;
  cursor: pointer;
  position: absolute;
  box-shadow: 2px 0 10px 0 rgba(113, 113, 113, 0.14);
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;


  &:hover {
    background-color: $brandColor;
    svg{
      path{
        stroke: white!important;
      }
    }
  }
}

.prev-btn {
  left: 0;
  .prev-icon {
    transform: rotate(180deg);
  }
}

.next-btn {
  right: 0;
  transform: translate(50%, -50%);

  .next-icon {
    transform: rotate(180deg);
  }
}

.disabled {
  cursor: unset;
  background-color: $borderColor !important;
}

@media screen and (max-width: 1600px){
  .link-button{
    height: 54px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1400px){
  .link-button{
    height: 50px;
    font-size: 16px;
    padding: 0 40px;
  }
}

@media screen and (max-width: 1300px) {
  .arrows {
  }
    .next-btn, .prev-btn {
      width: 44px;
      height: 44px;
    }
}

@media screen and (max-width: 1050px){
  .link-button{
    height: 44px;
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .link-button {
    font-size: 13px;
  }

  .next-btn, .prev-btn{
    width: 36px;
    height: 36px;
    svg, img {
      width: 18px;
    }
  }
  .next-btn{
    right: 10px;
  }
  .prev-btn{
    left: 10px;
  }
}



@media screen and (max-width: 600px) {
  .arrows {
    .vertical {
      display: none;
      visibility: hidden;
    }
  }

  .link-button{
    height: 36px;
    font-size: 12px;
    padding: 0 30px;
  }
}

@media screen and (max-width: 450px) {
  .arrows {
    .prev-btn {
      left: 4px;
    }

    .next-btn {
      right: 4px;
    }
  }
}

