@import "../mainVariables";
@import "../mixins";

.homepage_projects-wrapper{
  width: 100%;
  padding: 0 0 0  $mainPadding ;
  position: relative;
  background-color: #F2F4F7;
  overflow: hidden;
  .homepage_projects-block{
    //width: 1240px;
    .homepage_projects-content{
      width:1120px;
      padding-top: 90px;
      z-index: 999;
      position: relative;
      display: flex;
      justify-content: space-between;
      .text-content{
        width: 60%;
        p{
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
          margin-bottom: 12px;
          text-transform: uppercase;
        }
        .title{
          font-size: 36px;
          font-weight: 600;
          line-height: 44px;
          letter-spacing: -0.02em;
          text-align: left;
          margin-bottom: 20px;
        }
      }
      .bg-wrapper{
        width: 40%;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        right: 0;
        bottom: -240px;
      }
    }
    .projects-block{
      margin-top: 54px;
      .project{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin:20px 0;
        .project-title{
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0;
          text-align: left;
          color:$textColor;
        }
        .project-description{
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color:$textColor;
          word-break: break-all;
          margin-top: 12px;
        }
        .options-wrapper{
          .options{
            margin-top: 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #475467;
            svg{
              margin-right: 8px;
            }
          }
        }
        .project-more-news{
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          color:$brandColor;
          cursor: pointer;
          margin-top: 8px;
          svg{
            margin-left:4px;
            transition: transform 0.2s;
            transform: rotate(0deg);
          }
          &:hover{
            svg{
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }
      .img-wrapper{
        width: 600px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        right: -24px;
        z-index: 1000;
        .projectBg{
          position: absolute;
          top: -26px;
          right: -30px;
        }

        .img{
          width: 700px;
          height: 460px;
          overflow: hidden;
          z-index: 999;
          border: 23px solid $textColor;
          border-right: unset;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .homepage_projects-block{
      width: 100%;
      .homepage_projects-content{
        width: 1020px;
      }
    }
  }
  @media screen and (max-width:1100px){
    padding-bottom: 80px;
    padding-right:$mainPadding;
    .homepage_projects-block{
      .homepage_projects-content{
        width: 100%;
        padding-top: 24px;
        .text-content{
          width: 100%;
          .title{
            font-size: 26px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: -0.02em;
            text-align: left;
          }
        }
        .bg-wrapper{
          display: none;
        }
      }
      .projects-block{
        margin-top: 40px;
        .img-wrapper{
          display: none;
        }
      }
    }
  }
}