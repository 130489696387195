@import "../mainVariables";
@import "../mixins";


.mainBlock{
  width: 100%;
  overflow: hidden;
  background-color: #F2F4F7;
  .info-block{
    /*todo var(--mainPadding) փոխել*/
    padding: 60px $mainPadding 140px;
    width: 1120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text{
      width: 1020px;
      .title{
        font-size: 52px;
        font-weight: 700;
        line-height: 68px;
        letter-spacing: -0.02em;
        text-align: left;
      }
      .description{
        width: 80%;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        margin-top: 24px;
      }
      .main-link{
        width: 140px;
        height: 46px;
        border-radius: 24px;
        background-color:$brandColor;
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        padding: 12px 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        cursor: pointer;
      }
    }
    .consultation{
      display:none;
    }
  }

  @media screen and (max-width: 1100px) {
    .info-block{

      .text{
        width: 100%;
        .title{
          font-size: 34px;
          font-weight: 700;
          line-height: 46px;
          letter-spacing: -0.02em;
          text-align: left;

        }
        .description{
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        .main-link{
          width: 100%;
        }
      }
    }
  }
}
