@import "../mainVariables";
@import "../mixins";

.slider-wrapper {
  width: 100%;
  background-color: #FFFFFF;
  position: relative;

  .right-back{
    position: absolute;
    right: 0;
    top: -84%;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  .left-back{
    position: absolute;
    left: -184px;
    top: -66px;
  }
  .slider {
    margin: 0 $mainPadding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -66px;
    background: linear-gradient(78.63deg, #FFFFFF 79.81%, rgba(255, 255, 255, 0.955202) 84.46%, rgba(255, 255, 255, 0.929138) 85.7%, rgba(255, 255, 255, 0.884147) 87.68%, rgba(255, 255, 255, 0.838233) 89.35%, rgba(251, 251, 251, 0.750299) 91.95%, rgba(249, 249, 249, 0.698205) 93.74%, rgba(237, 236, 236, 0.642514) 96.6%, rgba(245, 245, 245, 0.487855) 99.64%, #FFFFFF 102.48%);

    .slider-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 1120px;
      padding: 72px 72px 52px;

      .slider-title {
        width: 60%;
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #101828;
      }

      .slider-description {
        width: 76%;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        padding-top: 24px;
        color: #475467;
      }

      .slider-link {
        display: flex;
        align-items: center;
        padding-top: 12px;
        cursor: pointer;

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0;
          text-align: center;
          color: $brandColor;
        }
        svg{
          transition: transform 0.2s;
          transform: rotate(0deg);
        }
        &:hover{
          svg{
            transform: rotate(0deg) translateX(6px);
          }
        }
      }
    }
    .slider-blocks{
      width: 100%;
      //width: 1120px;
      margin-bottom: 100px;
      .slick-slide{
        //width: 100%!important;
        .news-wrapper{
          width: unset!important;
          .news-item{
            border: 1px solid #101828;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            margin: 20px 24px 0 0;
            padding: 12px 32px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  @media screen and (max-width:1400px){
    .right-back{
      top:-50%;
    }
    .left-back{
      left: -204px;
    }
  }

  @media screen and (max-width: 1300px){
    .left-back{
      left: -222px;
    }
    .slider{
     .slider-info{
       width: 1020px;
     }
      .slider-blocks{
        //width: 1020px;
      }
    }
  }

  @media screen and (max-width: 1100px){
   .right-back{
     display: none;
   }
    .left-back{
      display: none;
    }
    .slider{
      position: unset;
      margin: unset;
      .slider-info{
        width: 100%;
        padding: 32px 16px;
        .slider-title{
          width: 100%;
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: -0.02em;
          text-align: left;

        }
        .slider-description{
          width: 100%;
          font-size: 16px;
        }

      }
      .slider-blocks{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}
