@import "../mainVariables";
@import "../mixins";

.contact2-wrapper{
  width: 100%;
  position: relative;
  background-color: white;

  .redBg{
    width: 238px;
    height: 230px;
    rotate: 160deg;
    position: absolute;
    top: 8.3%;
    left: -68px;
    background-color:$brandColor;
    @media screen and (max-width:1300px){
      //left: -633px;
    }
  }
  .grayBg{
    width: 380px;
    height: 240px;
    background-color: #D0D5DD;
    position: absolute;
    right: -160px;
    rotate: -30deg;
    top: -33px;
    @media screen and (max-width: 1300px){
      top: -10px;
    }
  }

  .contact2-block{
    background-color:$textColor;
    padding: 60px;
    margin: 0 $mainPadding 60px;
    position: relative;
    .subtitle{
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: #F2F4F7;
      text-transform: uppercase;
    }
    .contact-main-block{
      display: flex;
      justify-content: space-between;
      //align-items: center;
      .main-wrapper{
        width: 48%;
        display: flex;
        flex-direction: column;
        .contact-main-info{
          .contact-main-title{
            font-size: 36px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: 0;
            text-align: left;
            color: white;
          }
          .contact-main-description{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            margin-top: 16px;
            color: white;
          }
        }
        .contact-download-wrapper{
          .download-title{
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: white;
          }
          .download-block{
            width: 240px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid white;
            margin-top: 12px;
            padding-bottom: 4px;
            cursor: pointer;
            span{
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
              color: white;
              margin-left: 6px;
            }
          }
          .contact-main-btn{
            margin-top: 24px;
            width: 240px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 24px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: center;
            color: #101828;
          }
        }
      }
      .contact2-text-wrapper{
        width: 50%;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: white;
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 1100px){

    .redBg{
      display: none;
    }
    .grayBg{
      display: none;
    }
    .contact2-block{
      margin: unset;
      padding: 60px 28px 80px;
      .contact-main-block{
        flex-direction: column;
        .main-wrapper{
          width: 100%;
          .contact-main-info{
            .contact-main-title{
              font-size: 26px;
              font-weight: 600;
              line-height: 38px;
              letter-spacing: 0;
              text-align: left;
            }
            .contact-main-description{
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0;
              text-align: left;
            }
          }
          .contact-download-wrapper{
            margin-top: 32px;
            .download-title{
              display: none;
            }
            .download-block{
              display: none;
            }
          }
        }
        .contact2-text-wrapper{
          display: none;
        }
      }
    }
  }
}